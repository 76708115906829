import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom/cjs/react-router-dom";
import DisplayImage from "../common/DisplayImage";
import CardTemplate from "../common/CardTemplate";

const RankNewOrLogin = ({ mode, theMode, catSelected, loggedIn, keyId }) => {
  const showAddOrLogin = () => {
    if (theMode.add === mode && (!loggedIn || !catSelected))
      return showLoginMsg();
  };

  const showLoginMsg = () => {
    return !loggedIn ? (
      <div className="text-center">
        <Link to="/login" className="btn-success">
          Login
        </Link>{" "}
        {catSelected ? "" : "and select a category "} to add an item
      </div>
    ) : (
      <div className="text-center">Select a category to add item</div>
    );
  };

  const imageSection = () => (
    <DisplayImage rank={{}} mode={mode} theMode={theMode} loggedIn={loggedIn} />
  );
  const titleSection = () => <div>Add item to rank</div>;
  const contentSection = () => <div>{showAddOrLogin()}</div>;
  return (
    <CardTemplate
      imageSection={imageSection}
      titleSection={titleSection}
      contentSection={contentSection}
      keyId={keyId}
    />
  );
};

RankNewOrLogin.propTypes = {
  mode: PropTypes.number,
  theMode: PropTypes.object,
  catSelected: PropTypes.bool,
  loggedIn: PropTypes.bool,
  keyId: PropTypes.string,
};

export default RankNewOrLogin;
