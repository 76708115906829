import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import RankContext from "../../context/rank/rankContext";
import Spinner from "../layout/Spinner";

import { theMode, buttonPrefix } from "../rank/rank_resuse";
import AgainStarsComments from "../common/AgainStarsComments";
import { ActionButton } from "../../utils/functions_dates";

// TO-DO: default users vote into the edit form on load

const VoteItemAddEdit = ({
  mode,
  vote,
  onChange,
  isUsersVote,
  isUsersRank,
  onSaveAdd,
  keyId,
}) => {
  const rankContext = useContext(RankContext);
  const {
    loading_votes,
    current_category,
    current_vote,
    vote_selected,
    clearCurrentVote,
  } = rankContext;

  let isCurrentVote = vote_selected && current_vote?._id === vote?._id;
  let rankUnderEdit = theMode.edit === mode;
  let voteMode =
    isUsersVote && (isCurrentVote || rankUnderEdit)
      ? theMode.edit
      : theMode.show;

  useEffect(() => {
    // eslint-disable-next-line
  }, [vote]);

  const showButtons = (mode, theMode) => {
    let buttonStyle = "btn btn-sm btn-success";

    return mode === theMode.addParent ? (
      <div>
        {ActionButton(
          buttonStyle,
          onSaveAdd,
          // <i className="fa fa-save" />,
          "Save Vote",
          {
            data: vote,
            action: "save",
          },
          keyId
        )}
        {ActionButton(
          buttonStyle,
          clearCurrentVote,
          // <i className="fa fa-close" />,
          "Cancel",
          "",
          keyId
        )}
      </div>
    ) : mode === theMode.add ? (
      ActionButton(
        buttonStyle,
        onSaveAdd,
        // <i className="fas fa-plus-circle" />,
        "Add Vote",
        {
          data: vote,
          action: "add",
        },
        keyId
      )
    ) : (
      <div>
        {ActionButton(
          buttonStyle,
          onSaveAdd,
          // <i className="fa fa-save" />,
          "Save Vote",
          {
            data: vote,
            action: "save",
          },
          keyId
        )}

        {ActionButton(
          buttonStyle,
          clearCurrentVote,

          "Cancel",
          "",
          keyId
        )}
      </div>
    );
  };

  if (vote === undefined || vote === null) return " ";
  if (loading_votes && vote === undefined) return <Spinner />;

  const buttonPre = buttonPrefix(vote, current_category);

  const voteKey = vote._id || "add";
  const highlightUsersVote = isUsersVote
    ? " border border-3 border-success "
    : "";
  return (
    <div className={"container" + highlightUsersVote} key="vote-card-container">
      <AgainStarsComments
        record={vote}
        theMode={theMode}
        mode={voteMode}
        buttonPre={buttonPre}
        onChange={onChange}
        keyId={"votes-comments-" + voteKey}
      />
      {isUsersVote && !isUsersRank ? (
        <div className={"row my-1"} key={"card-body-r1-" + voteKey}>
          <div className="col text-center " key={"card-body-r1c1-" + voteKey}>
            <div className="float-right">{showButtons(voteMode, theMode)}</div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

VoteItemAddEdit.propTypes = {
  mode: PropTypes.number,
  vote: PropTypes.object.isRequired,
  setVoteRecord: PropTypes.func,
  isUsersVote: PropTypes.bool.isRequired,
  isUsersRank: PropTypes.bool.isRequired,
  keyId: PropTypes.string,
  rank: PropTypes.object,
  // isUsersRank: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onSaveAdd: PropTypes.func,
  onVoteChange: PropTypes.func,
  onSaveAddRank: PropTypes.func,
};

export default VoteItemAddEdit;
