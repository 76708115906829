import React, { Fragment } from "react";
// import authContext from '../../context/auth/authContext';

export const default_rank_set = {
  category: "",
  name: "",
  votes: "",
};

export const defaultRankSet = (categoryId) => {
  return {
    _id: "new",
    user: "",
    // category: "",
    // category: current_category?._id ?? {},
    category: categoryId ?? {},
    name: "",
    again: "",
    attributes: {},
    image: "",
    stars: "",
    votes: [],
    // voteCount: 1,
    // avgStars: 1,
    comments: "",
  };
};

export const isObject = (value) =>
  value && typeof value === "object" && value.constructor === Object;

export const isString = (value) =>
  typeof value === "string" || value instanceof String;

export const isArray = (value) =>
  value && typeof value === "object" && value.constructor === Array;

export const theMode = { show: 0, edit: 1, add: 2, addParent: 3 };

export const showMode = (mode, theMode) =>
  Object.entries(theMode).map((arr) => (arr[1] === mode ? arr[0] : ""));

// export const buttonPrefix = (rank, current_category) =>
//   rank?.category !== undefined
//     ? rank?.category[0].prefix
//     : current_category?.prefix || "do";

export const buttonPrefix = (rank, current_category) =>
  rank?.category?.prefix ?? current_category?.prefix ?? "do";

export const cardClass = (rank) =>
  !rank.again || rank.voteCount === 0
    ? "bg-light"
    : rank.votes > 0
    ? "bg-success"
    : "bg-danger";

export const showAgainButtons = (
  mode,
  theMode,
  show,
  data,
  buttonPre,
  onChange
) => {
  let keyExists = "again" in data;
  const highlightAgain =
    (keyExists && data.again === true) ?? data?.avgStars >= 2.5;
  const highlightNotAgain =
    (keyExists && data.again === false) ?? data?.avgStars < 2.5;

  return show ? (
    <Fragment>
      {againButton(mode, theMode, true, buttonPre, onChange, highlightAgain)}
      {againButton(
        mode,
        theMode,
        false,
        buttonPre,
        onChange,
        highlightNotAgain
      )}
    </Fragment>
  ) : (
    <Fragment>
      {againButton(mode, theMode, true, buttonPre, onChange, highlightAgain)}
      {againButton(
        mode,
        theMode,
        false,
        buttonPre,
        onChange,
        highlightNotAgain
      )}
    </Fragment>
  );
};

export const againButton = (
  mode,
  theMode,
  again,
  prefix,
  onChange,
  highlighted
) => {
  let active = theMode.show !== mode; // only make the button active if edit or add
  let color = again ? "success" : "danger";
  let className = "btn btn-sm text-white ";
  className += " btn-" + color;
  className += active ? " active" : " disabled";
  // className += highlighted ? " border-3 border-" + color : "";
  className += highlighted ? " border-3 border-" + "warning" : "";
  return (
    <button
      className={className}
      style={{ minWidth: "8rem" }}
      name={again ? "vote.up" : "vote.down"}
      onClick={onChange}
      value={again ? true : false}
      disabled={!active}
      aria-disabled={!active}
      aria-pressed={highlighted}
    >
      {(again ? "" : "won't ") + prefix + " again"}
    </button>
  );
};

// export const againButton = (again, prefix, onChange, enabled) => {
//   return again ? (
//     <button
//       className={
//         "btn btn-sm btn-success text-white active"
//         // "btn btn-sm btn-success text-white " + (enabled ? "active" : "disabled")
//       }
//       style={{ minWidth: "8rem" }}
//       name={"vote.up"}
//       onClick={onChange}
//       value={true}
//       // disabled={!enabled}
//       aria-disabled={!enabled}
//       aria-pressed={enabled}
//     >
//       {prefix + " again"}
//     </button>
//   ) : (
//     <button
//       className={
//         "btn btn-sm btn-danger text-white disabled"
//         // "btn btn-sm btn-danger text-white " + (enabled ? "active" : "disabled")
//       }
//       style={{ minWidth: "8rem" }}
//       name={"vote.down"}
//       onClick={onChange}
//       value={false}
//       // disabled={!enabled}
//       aria-disabled={!enabled}
//       aria-pressed={enabled}
//     >
//       {"won't " + prefix + " again"}
//     </button>
//   );
// };

export const voteOnChange = (name) => {
  let chg = name.indexOf(".up") > -1 ? true : false;
  return ["again", chg];
};

export const imageOnChange = (e) => {
  return [e.target.name, e.target.files[0]];
};

export const attrOnChange = (rank, e) => {
  let name = e.target.name;
  let names = name.split("."); // get attr index
  let key = names[1]; // get attributes[index]

  name = "attributes";
  let val = rank.attributes;
  val[key] = e.target.value;
  return [name, val];
};

export const cat_selected = (obj) => obj?._id !== undefined;

export const isArrayEmpty = (obj) =>
  !(obj !== undefined && obj !== null && obj.length !== 0);

export const getUserID = (authContext) =>
  authContext.isAuthenticated && authContext.user ? authContext.user._id : "";

export const userMatches = (user, authContext) =>
  getUserID(authContext).toString() === user;

export const showButtonFromArray = (arr, fns) => arr.map((but) => fns[but]());

export const userCreatedRank = (record, current_rank, authContext) =>
  rankIsCurrent(current_rank, record._id) &&
  userMatches(record.user, authContext);

export const rankSelected = (current_rank) =>
  current_rank && current_rank !== null;

export const rankIsCurrent = (current_rank, id) =>
  rankSelected(current_rank) && current_rank._id === id;

// const getCategoryAttrs = (id) => findInObj(categorys, '_id', id).attributes;

// // -------------- GENERIC FUNCTIONS
// const fields = [
//   {
//     field: 'category',
//     title: 'Category',
//     type: 'string',
//   },
//   { field: 'name', title: 'Name', type: 'name' },
//   { field: 'attributes', title: 'Attributes', type: 'string' },
//   {
//     field: 'image',
//     title: 'Image',
//     type: 'image',
//   },
// ];

// // -------------- DISPLAY FUNCTIONS

// const getCategoryRecord = (name) => {
//   if (categorys !== undefined && categorys !== null && categorys !== '') {
//     let obj = categorys.find((o) => o.name === name);
//     return obj;
//   }
// };

// // edit when???

// const showSingleAttribute = (field_name, index, value) => {
//   let options = {};
//   if (mode === edit) options.value = value;
//   return (
//     <input
//       {...options}
//       key={field_name}
//       type='text'
//       className='grid-input-acct'
//       name={'attributes.' + field_name}
//       placeholder={field_name.toLowerCase()}
//       onChange={onChange}
//     />
//   );
// };
// const showCategory = (categorys) => (
//   <div>
//     {categorys !== undefined && categorys !== null && categorys.length > 0
//       ? categorys[0].name
//       : ' '}
//   </div>
// );
