import React, { Fragment, useContext, useEffect, useState } from "react";
import RankContext from "../../context/rank/rankContext";
import { defaultRankSet, theMode } from "./rank_resuse";
import Spinner from "../layout/Spinner";
import RankItem from "./RankItem";

let debug = 0;
const db = debug >= 1 ? true : false;

const RankList = () => {
  let dp = ".com.ranks.ranklist";

  const rankContext = useContext(RankContext);
  const {
    ranks,
    getRanksSet,
    loading_rank,
    current_category,
    category_selected,
    ranks_filtered,
    filtered,
  } = rankContext;

  useEffect(() => {
    if (db) console.log(`${dp}:start`);
    // // if a filter set exists, use it instead of ranks else use ranks set
    // //   no need to assign to the displaySet as checks will be done in the jsx to see if records exist in either filtered or ranks
    if (category_selected) {
      getRanksSet({ category_id: current_category._id });
    } else {
      getRanksSet();
    }
    if (db) console.log(`${dp}:finish`);
    // eslint-disable-next-line
  }, [current_category]);

  // Ranks
  // -- RankList
  //   Show
  //    -- RankItem (show) - for any user
  //    -- VoteList (show) - for any user
  //   Edit Rank & Vote
  //    -- RankItem (edit) - only for same user of rank & vote
  //    -- VoteItem (edit) - only for same user of rank & vote
  //   Edit Vote
  //    -- RankItem (show) - for any user
  //    -- VoteItem (edit) - for any user who added a vote
  //   Add Rank & Vote
  //    -- RankItem (add) - for any user
  //    -- VoteItem (add) - for any user
  //   Add Vote
  //    -- RankItem (show) - for any user
  //    -- VoteItem (add) - for any user

  // const show = 0, edit = 1, add= 2;
  const [showAddForm, setShowAddForm] = useState(false);

  const showRanks = (ranks) =>
    ranks.map((rank) => {
      // let showVotes = showTheVoteList(rank, theMode.show, theMode);
      const keyId = "rank-" + (rank?._id ?? "add");

      return (
        <Fragment key={"frag-" + keyId}>
          <RankItem
            key={rank._id}
            keyId={rank._id}
            rankRecord={rank}
            mode={theMode.show}
          />
        </Fragment>
      );
    });

  // key, showVote, setVoteList
  const showRankList = () => {
    if (ranks_filtered) return showRanks(filtered);
    if (ranks?.length > 0) return showRanks(ranks);
    return (
      <div className="col">
        <span>No ranks exist, please add an rank</span>
      </div>
    );
  };

  const addRankForm = () => {
    const showHideAddButton = (title, showHide, func) => {
      let ks = "-add-form-button";
      return (
        <div className="row align-items-center" key={`${title}${ks}-row`}>
          <div className="col-auto" key={`${title}${ks}-col-button`}>
            <button
              className="border-0 bg-white"
              onClick={() => func(!showHide)}
              key={`${title}${ks}`}
            >
              <span
                className="material-symbols-outlined"
                key={`${title}${ks}-sign`}
              >
                {!showHide ? "add_circle" : "cancel"}
              </span>{" "}
            </button>
          </div>
          <div className="col" key={`${title}${ks}-col-text`}>
            <span key={`${title}${ks}-span-text`}>Add {" " + title}</span>
          </div>
        </div>
      );
    };

    const showAddRankForm = () => {
      if (!showAddForm) return "";
      return (
        <RankItem
          keyId={"rank-item-add"}
          rankRecord={defaultRankSet(current_category?._id ?? "pending")}
          mode={theMode.add}
        />
      );
    };

    return (
      <Fragment>
        {showHideAddButton("Rank", showAddForm, setShowAddForm)}
        <div className="row ">
          <div className="col">{showAddRankForm()}</div>
        </div>
      </Fragment>
    );
  };

  if (loading_rank) return <Spinner />;
  return (
    <Fragment>
      {addRankForm()}
      {showRankList()}
    </Fragment>
  );
};

export default RankList;
