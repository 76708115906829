import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import RankContext from "../../context/rank/rankContext";
import Spinner from "../layout/Spinner";

import {
  theMode,
  buttonPrefix,
  voteOnChange,
  imageOnChange,
} from "../rank/rank_resuse";
import { voteButton } from "./voteReuse";
import AgainStarsComments from "../common/AgainStarsComments";
import { ActionButton } from "../../utils/functions_dates";

// TO-DO: default users vote into the edit form on load

const VoteItem = ({
  mode,
  voteRecord,
  onVoteChange,
  isUsersVote,
  rank,
  onSaveAddRank,
  keyId,
}) => {
  const rankContext = useContext(RankContext);
  const {
    // rank,
    loading_votes,
    addVote,
    updateVote,
    current_category,
    current_vote,
    vote_selected,
    setCurrentVote,
    clearCurrentVote,
  } = rankContext;

  // const [vote, setVote] = useState(defaultVoteSet);
  const [vote, setVote] = useState(voteRecord);

  let isCurrentVote = vote_selected && current_vote?._id === voteRecord?._id;
  let rankUnderEdit = theMode.edit === mode;
  let voteMode =
    isUsersVote && (isCurrentVote || rankUnderEdit)
      ? theMode.edit
      : theMode.show;

  // console.log(voteMode);
  // if ((voteMode = theMode.edit)) {
  //   console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^");
  //   console.log(voteRecord);
  // }

  useEffect(() => {
    if (voteMode === theMode.show) {
      setVote(voteRecord);
    } else if (voteMode === theMode.edit) {
      setVote(current_vote);
    } else if (voteMode === theMode.addParent) {
      setVote(voteRecord);
      // setVote(defaultVoteSet);
    } else {
      setVote(voteRecord);
    }
    // eslint-disable-next-line
  }, [voteRecord]);

  // console.log(isUsersVote);
  const buttonStyle = "btn btn-outline-light btn-sm";

  const onChange = (...args) => {
    let name = args[2] || args[0].target?.name;
    let chg;

    if (name === "image") {
      chg = imageOnChange(args[0]);
    } else if (name.indexOf("vote.") > -1) {
      chg = voteOnChange(name);
    } else if (name.includes("stars")) {
      chg = ["stars", args[0]];
    } else {
      chg = [name, args[0].target.value];
    }

    setVote({
      ...vote,
      [chg[0]]: chg[1],
    });
  };

  const onSaveAddVote = (args) => {
    let vote = args.data;
    console.log("#########################");
    console.log(vote);

    // update rank if it's the users rank also
    let isUsersRank = vote?.user === rank?.user;
    if (isUsersRank) {
      onSaveAddRank({ data: rank, action: args.action });
    }

    if (args.action === "add") {
      addVote(vote);
      //addRank(rank)
    } else {
      updateVote(vote);
    }
    clearForm();
  };

  const clearForm = () => {
    // setRank(default_rank_set);
    // clearCurrentRank();
  };

  const showButtons = (mode, theMode) => {
    let buttonStyle = "btn btn-sm btn-success";
    return mode === theMode.show ? (
      ActionButton(
        buttonStyle,
        setCurrentVote,
        // <i className="far fa-edit" />,
        "Edit Vote",
        vote,
        keyId
      )
    ) : mode === theMode.addParent ? (
      <div>
        {ActionButton(
          buttonStyle,
          onSaveAddVote,
          // <i className="fa fa-save" />,
          "Save Vote",
          {
            data: vote,
            action: "save",
          },
          keyId
        )}
        {ActionButton(
          buttonStyle,
          clearCurrentVote,
          // <i className="fa fa-close" />,
          "Cancel",
          "",
          keyId
        )}
      </div>
    ) : mode === theMode.add ? (
      ActionButton(
        buttonStyle,
        onSaveAddVote,
        // <i className="fas fa-plus-circle" />,
        "Add Vote",
        {
          data: vote,
          action: "add",
        },
        keyId
      )
    ) : (
      <div>
        {ActionButton(
          buttonStyle,
          onSaveAddVote,
          // <i className="fa fa-save" />,
          "Save Vote",
          {
            data: vote,
            action: "save",
          },
          keyId
        )}

        {ActionButton(
          buttonStyle,
          clearCurrentVote,
          // <span
          //   className="material-symbols-outlined b-0 p-0"
          //   style={{ fontSize: "0.5rem" }}
          // >
          //   close
          // </span>,
          "Cancel",
          "",
          keyId
        )}
      </div>
    );
  };

  if (vote === undefined || vote === null) return " ";
  if (loading_votes && vote === undefined) return <Spinner />;

  const buttonPre = buttonPrefix(vote, current_category);
  // const buttonPre =
  //   mode === theMode.add || mode === theMode.show
  //     ? buttonPrefix(defaultCategory, current_category)
  //     : buttonPrefix(vote, current_category);

  const voteKey = vote._id || "add";
  const highlightUsersVote = isUsersVote
    ? " border border-3 border-success "
    : "";
  return (
    <div
      className={"container " + highlightUsersVote}
      key="vote-card-container"
    >
      <AgainStarsComments
        record={vote}
        theMode={theMode}
        mode={voteMode}
        buttonPre={buttonPre}
        onChange={onChange}
        onVoteChange={onVoteChange}
        keyId={"votes-comments-" + voteKey}
      />
      {isUsersVote ? (
        <div className={"row my-1"} key={"card-body-r1-" + voteKey}>
          <div className="col text-center " key={"card-body-r1c1-" + voteKey}>
            <div className="float-right">{showButtons(voteMode, theMode)}</div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

VoteItem.propTypes = {
  mode: PropTypes.number,
  voteRecord: PropTypes.object.isRequired,
  onVoteChange: PropTypes.func,
  isUsersVote: PropTypes.bool.isRequired,
  keyId: PropTypes.string,
  rank: PropTypes.object,
  // isUsersRank: PropTypes.bool.isRequired,
  onSaveAddRank: PropTypes.func,
};

export default VoteItem;

// <div className={"row"} key={"edit-vote-row-" + voteKey}>
//         <div className={"col bg-info"} key={"edit-vote-col-" + voteKey}>
//           {/* add button to set mode to edit for vote */}
//           {/* <button
//             className="btn btn-success"
//             onClick={() => setCurrentVote(vote)}
//           >
//             Edit
//           </button> */}
//           Here
//           {/* {ActionButton("btn btn-success", "", "Edit", vote)} */}
//           {/* {ActionButton(buttonStyle, "", <i className="far fa-edit" />, vote)} */}
//           {/* {mode === theMode.show ? (
//             <button
//               className="btn btn-success"
//               onClick={() => setCurrentVote(vote)}
//             >
//               Edit
//             </button>
//           ) : mode === theMode.edit ? (
//             <button
//               className="btn btn-success"
//               onClick={() =>
//                 onSaveAddVote({
//                   data: vote,
//                   action: "save",
//                 })
//               }
//             >
//               Save
//             </button>
//           ) : (
//             <button
//               className="btn btn-success"
//               onClick={() =>
//                 onSaveAddVote({
//                   data: vote,
//                   action: "add",
//                 })
//               }
//             >
//               Save
//             </button>
//           )} */}
//           {showButtons(mode)}
//           Here
//         </div>
//       </div>
